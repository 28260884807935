import React, { useEffect, useState } from 'react';
import config from '../coreFIles/config';
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Header from '../directives/header';
import Footer from '../directives/footer';
import Sidebar from '../directives/sidebar';
import { searchorderproductAction, getAllUserslistAction, selecteduseraddressAction, calculateorderShippingAction, CreateUserOrderQuoteAction, AddtoquoteCartAction, getquoteOrderTotalAction,PlaceCODOrderAction, removecartProductAction, updatecartqytAction, UpdateCartProductQtyAction } from '../Action/action';
import Cookies from "js-cookie";
const loginData = (!Cookies.get('loginSuccessfastenerwarehouseAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessfastenerwarehouseAdmin'));
const Createorder = () => {
	if (!loginData || loginData == '') {
		window.location.href = `${config.baseUrl}`;
	}
	if(loginData.role != config.role_admin){
		window.location.href = `dashboard`;	
	}
    const [orderForm, setOrderForm] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        company_name: '',
        orderProducts: []
    });
    const [quotecode, SetQuotecode] = useState('')
    const [quote_id, Setquote_id] = useState('')
    const [validationError, setValidationError] = useState({});
    const [users, setUsers] = useState([]);  
    const [searchProduct, setSearchProduct] = useState([]);
    const [orderProducts, setorderProducts] = useState([])
    const [defaultAddress, setDefaultAddress] = useState({});
    const [defaultAddress2, setDefaultAddress2] = useState({});
    const [showNewAddress, setshowNewAddress] = useState(1);
    const [UserAddress, setUserAddress] = useState([]);
    const [siteDeliveryAdd, setsiteDeliveryAdd] = useState({});
    const [totals, setTotals] = useState({});
    const [newAddressform, setnewAddressForm] 	= useState({ user_id:orderForm.user_id,first_name:'',last_name:'',phone:'', newaddress_1: '', newstate: '', newpostcode: '', newsuburb:'', shipto:1 });

    const [showAddressEditForm, setshowAddressEditForm] = useState(0);
   

    useEffect(() => {
        searchUsersApi();
    }, []);
    
    useEffect(() => {    
		const defaultAddr 	= UserAddress.find(address => address.shipto === 0 && address.set_default === 1);
		const defaultAddr2 	= UserAddress.find(address => address.shipto === 1 && address.set_default === 1);
		const siteAddress 	= UserAddress.find(address => address.shipto === 2 && address.set_default === 1);
		if (defaultAddr) {
		  setDefaultAddress(defaultAddr);
		}
		if (defaultAddr2) {
		  setDefaultAddress2(defaultAddr2);
		}
		if (siteAddress) {
		  setsiteDeliveryAdd(siteAddress);
		}
		updateordersummery(quote_id);		
	  }, [UserAddress]);

      useEffect(() => { 
		updateordersummery(quote_id);
	  }, [showNewAddress]);  
	 
    // search users from API
    const searchUsersApi = async (user) => {
        const res = await getAllUserslistAction();
        if (res.success) {
            let users = res.data
            setUsers(users);

        } else {
            setUsers([]);
        }
    };

    const getselecteduserAddressApi = async (id) => {
        console.log(id)

        let res = await selecteduseraddressAction({ user_id: id })
        if (res.success) {
            let address = res.data
         
            const defaultAddr = address.find(address => address.shipto === 0 && address.set_default === 1);
            const defaultAddr2 = address.find(address => address.shipto === 1 && address.set_default === 1);
            const siteAddress = address.find(address => address.shipto === 2 && address.set_default === 1);
            if (defaultAddr) {
                setDefaultAddress(defaultAddr);
            }
            if (defaultAddr2) {
                setDefaultAddress2(defaultAddr2);
            }
            if (siteAddress) {
                setsiteDeliveryAdd(siteAddress);
            }
          
        }
        else {
            setUserAddress([])
        }



    }
    const handleAddressChange = (e) => {
		setshowNewAddress(Number(e.target.value)); 
		if(Number(e.target.value)==3)
		{		
			setnewAddressForm((old) => {
                
				return { ...old, 'shipto': 2 };
			});
		}else{
			setnewAddressForm((old) => {
				return { ...old, 'shipto': 1 };
			});
		}
	  };
    // handle user selection
    const selectUserHandler = async (user) => {
        getselecteduserAddressApi(user.id)
        setOrderForm({
           
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            phone: user.phone,
            company_name: user.company,
            orderProducts: [],
            user_id: user.id,
            tired_id: user.tiered_group
        });


    };
    // create quote
    const CreateUserOrderQuoteApi = async () => {
        let res = await CreateUserOrderQuoteAction({ user_id: orderForm.user_id, company: orderForm.company_name })
        if (res.success) {
			let quoteDetails =res.data;
            toast.success(res.msg);
            Setquote_id(quoteDetails.id)
            SetQuotecode(quoteDetails.quote_code);
        }
        else {
            toast.error(res.msg);
        }

    }

    // handle key press for phone number input
    const handleKeyPress = (e) => {
        const isValidInput = /^[0-9\b]+$/.test(e.key);
        if (!isValidInput) {
            e.preventDefault();
        }
    };

    // handle input values for form fields
    const inputHandler = (e) => {
        const { name, value } = e.target;
        setOrderForm((prev) => ({ ...prev, [name]: value }));
    };
    // search products from API
    const searchProductApi = async (str) => {
       if(quote_id ===''){
        toast.error("please select user")
       }
       else{
        if (str !== '') {
            const res = await searchorderproductAction({ str });
            if (res.success) {
                setSearchProduct(res.data);
            } else {
                setSearchProduct([]);
            }
        } else {
            setSearchProduct([]);
        }
    }
    }
  

    // remove product from the order
    const removeorderproduct = (part_number) => {
        setOrderForm((prevForm) => ({
            ...prevForm,
            orderProducts: prevForm.orderProducts.filter(product => product.part_number !== part_number)
        }));
    };
    // handle product selection
    const handleProductSelect = async (product) => {
      
        let res = await AddtoquoteCartAction({ product_id: product.id, hobson_part: product.hobson_part, user_id: orderForm?.user_id, cart_id: quote_id })
        updateordersummery()
        if (res.success) {
          
            setorderProducts(res.data)
        }

        else {

        }
        setSearchProduct([]);
    };
   
    const updateordersummery = async (c) => {
        let res = await getquoteOrderTotalAction({ cart_id: quote_id, address_id: showNewAddress, user_id: orderForm?.user_id });
        if (res.success) {
            setTotals(res.data);
        }

    }
   

    const calculateorderShippingApi = async (cart_id) => {
        let res = await calculateorderShippingAction({ cart_id, address_id: showNewAddress,user_id:orderForm.user_id });
        if (res.success) {
            setTotals(res.data);
        } else {
            toast.error(res.msg);
        }

    }
    const formatToTwoDecimals = (value) => {
        return value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      };

   
    // handle form submission
    const PlaceCODOrderApi = async(e) => {
        let res = await PlaceCODOrderAction({cart_id:quote_id,user_id:orderForm.user_id,address_id: showNewAddress,totals:totals})
        if(res.success){
            toast.success(res.msg)
        }
        else{
            toast.error(res.msg)
        }
    };
     const increaseProductQty = async (cart_id, product_id) => {

        let res = await updatecartqytAction({ cart_id: cart_id, product_id: product_id, user_id: orderForm.user_id, action: "inc" })
        if (res.success) {
            updateordersummery(cart_id)
            setorderProducts(res.data)
        }
        else {

        }
    }
    const decreaseProductQty = async (cart_id, product_id) => {

        let res = await updatecartqytAction({ cart_id: cart_id, product_id: product_id, user_id: orderForm.user_id, action: "dec" })
        if (res.success) {
            updateordersummery(cart_id)
            setorderProducts(res.data)
        }
        else {

        }
    }
    const handleProductQuantityChange = async (e, cart_id, product_id) => {
        const newQuantity = parseInt(e.target.value);
        // Update the quantity in your local state here
        setorderProducts((prevProducts) =>
            prevProducts.map((product) =>
                product.cart_quote_id === cart_id && product.product_id === product_id
                    ? { ...product, product_quantity: newQuantity }
                    : product
            )
        );
       
    };
	
	const updateProductQuantity = async (e,row) => {
        // console.log(row)
        let enteredQuantity = parseInt(e.target.value, 10);
        if (isNaN(enteredQuantity) || enteredQuantity < 0) {
            enteredQuantity = 0;
        }

        const cart_id= row.cart_quote_id
        const product_id= row.product_id
         const quantityBreak = row.sell_quantity_increment;
        //  console.log(quantityBreak)
         const updatedQuantity = Math.ceil(enteredQuantity / quantityBreak) * quantityBreak;      
        
         let productId =row.product_id;  
         
         setorderProducts((prevProducts) =>
            prevProducts.map((product) =>
                product.cart_quote_id === cart_id && product.product_id === product_id
                    ? { ...product, product_quantity: updatedQuantity }
                    : product
            )
        );

         let res = await UpdateCartProductQtyAction({
            cart_id: cart_id,
            product_id:productId,
            address_id: showNewAddress,
            user_id: orderForm?.user_id,
            qty: updatedQuantity,
        });
        if (res.success) {
            updateordersummery(cart_id)
            setTotals(res.data)
        }

    };
    const updateProductQuantityOnEnter = async (e,row) => {
        if (e.key === 'Enter') {
            let enteredQuantity = parseInt(e.target.value, 10);
            if (isNaN(enteredQuantity) || enteredQuantity < 0) {
                enteredQuantity = 0;
            }
            const cart_id= row.cart_quote_id
            const product_id= row.product_id
                 const quantityBreak = row.sell_quantity_increment;
                 console.log(quantityBreak)
                 const updatedQuantity = Math.ceil(enteredQuantity / quantityBreak) * quantityBreak;	  
                 console.log(updatedQuantity)
                let productId =row.product_id;  
                setorderProducts((prevProducts) =>
                    prevProducts.map((product) =>
                        product.cart_quote_id === cart_id && product.product_id === product_id
                            ? { ...product, product_quantity: updatedQuantity }
                            : product
                    )
                );

            
         let res = await UpdateCartProductQtyAction({
            cart_id: quote_id,
            product_id:productId,
            address_id: showNewAddress,
            user_id: orderForm?.user_id,
            qty: updatedQuantity,
        });
        if (res.success) {
            updateordersummery(cart_id)
            setTotals(res.data)
        }
        }
    };
    
     // remove product from the order
    const CartRemoveProduct = async (cart_id, product_id) => {

        Swal.fire({
            title: '',
            text: "Are you sure you want to remove this product?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await removecartProductAction({ product_id: product_id, cart_id: cart_id, user_id: orderForm.user_id });
                if (res.success) {
                    updateordersummery(cart_id)
                    setorderProducts(res.data)
                } else {
                    toast.error(res.msg);
                }
            }
        });

    }
    
    return (
        <>
            <Sidebar />
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                <Toaster />
                <Header />
                <div className="container-lg mb-4">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb my-0 ms-2">
                            <li className="breadcrumb-item">
                                <Link className="homelink" to={`${config.baseUrl}`}>Home</Link>
                            </li>
                            <li className="breadcrumb-item active"><span>Create order quote
                            </span></li>
                        </ol>
                    </nav>
                </div>
                <div className="body flex-grow-1 px-3">
                    <div className="container-lg">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card mb-4">
                                    <div className="card-header">
                                        <div className="row align-items-center">
                                            <div className="col-md-6 text-start">
                                                <span>Create order {quotecode == '' ? "" : `#${quotecode}`}</span>
                                            </div>
                                            <div className="col-md-6 text-end">
                                                <Link className="btn btn-primary editbtn text-white" to={`${config.baseUrl}orders`}>Back</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body inner-body">
                                        <form className="row g-3">
                                            <div className="col-md-4">
                                                <label className="form-label" htmlFor="Users">Users<span className="req-star">*</span></label>
                                                {quote_id == '' ?
                                                    <select
                                                        className={validationError.Users_Error ? 'form-control is-invalid' : 'form-control'}
                                                        id="Users"
                                                        name="Users"
                                                        onChange={(e) => selectUserHandler(users.find(user => user.id == e.target.value))}
                                                    >
                                                        <option value="">Select User</option>
                                                        {users.map((user) => (
                                                            <option key={user.id} value={user.id}>
                                                                {user.first_name} {user.last_name} ({user.email})
                                                            </option>
                                                        ))}
                                                    </select> :
                                                    <select
                                                        className={validationError.Users_Error ? 'form-control is-invalid' : 'form-control'}
                                                        id="Users"
                                                        name="Users"
                                                        onChange={(e) => selectUserHandler(users.find(user => user.id == e.target.value))}
                                                        disabled
                                                    >
                                                        <option value="">Select User</option>
                                                        {users.map((user) => (
                                                            <option key={user.id} value={user.id}>
                                                                {user.first_name} {user.last_name}  ({user.email})
                                                            </option>
                                                        ))}
                                                    </select>
                                                }


                                            </div>
                                            <div className="invalid-feedback">{validationError.Users_Error}</div>

                                            <div className="col-md-6 mt-5">
                                                {quote_id == '' ?
                                                    <a className="btn btn-primary createbtn" onClick={CreateUserOrderQuoteApi}
                                                    ><i className="fa fa-plus" ></i>  Create Quotes</a> :
                                                    <a className="btn btn-primary createbtn"
                                                    ><i className="fa fa-plus" ></i>  Create Quotes</a>}
                                            </div>
                                            <div className="col-md-4">
                                                <label className="form-label" htmlFor="orderProducts">Order Products<span className="req-star">*</span></label>
                                                <div className="order-product-search">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search Products"
                                                        onChange={(e) => searchProductApi(e.target.value)}
                                                    />
                                                    {searchProduct.length > 0 && (
                                                        <ul className="suburbList">
                                                            {searchProduct.map((row) => (
                                                                <li key={row.id} onClick={() => handleProductSelect(row)}>
                                                                    {row.hobson_part}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </div>
                                                <div className="invalid-feedback">{validationError.orderProductsError}</div>
                                            </div>
                                          
                                        </form>
                                        {orderProducts.length>0 && (
                                            <div className="row" id="tempproducts">
                                                <table className="table mb-0">
                                                    <thead className="table-light fw-semibold">
                                                        <tr className="align-middle table-heading">
                                                            <th>Name</th>
                                                            <th>Description</th>
                                                            <th>Price</th>
                                                            <th style={{textAlign:"center"}}>Quantity</th>
                                                            <th>SubTotal</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {orderProducts.map((row, index) => (
                                                            <tr key={index}>
                                                                <td>{row.product_sku}</td>
                                                                <td>{row.product_description}</td>
                                                                <td>${formatToTwoDecimals(row.display_product_price)} / {row.price_per}</td>
                                                                <td style={{textAlign:"center"}}>
																<div className="quntity-box d-flex gap-3 items-center ">
																	<div className="value-button mt-1 csr-ptr" id="decrease" onClick={() => decreaseProductQty(row.cart_quote_id, row.product_id)} >-</div>
																	<input type="text" className="form-control" style={{height:"25px",width:"80px",textAlign:"center"}} value={row.product_quantity} onChange={(e) => handleProductQuantityChange(e, row.cart_quote_id, row.product_id)} onBlur={(e)=>updateProductQuantity(e,row)}  onKeyDown={(e) => updateProductQuantityOnEnter(e,row)}/>
																	<div className="csr-ptr mt-1 " id="increase" onClick={() => increaseProductQty(row.cart_quote_id, row.product_id)} >+</div>
																</div>
																</td>
                                                                <td>{(row.product_per_price * row.product_quantity).toFixed(2)}</td>
                                                               <td>
                                                                    <span className="csr-ptr" onClick={() => CartRemoveProduct(row.cart_quote_id, row.product_id)}>x</span>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                        {orderProducts.length>0 ?
                                            <div className="delivery-method mt-3">
                                                <div className="row">
                                                    <div className="col-md-7">
                                                        <div className="row">
                                                            <div className="col-md-4 d-none">
                                                                <div className="deliver-block">
                                                                    <h4>DELIVERY METHOD</h4>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label" htmlFor="shipmethod-CMM0016-2">
                                                                            <input type="radio" className="form-check-input shipmethod" defaultChecked />
                                                                            Delivered
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="deliver-section">
                                                                    <h4>DELIVERY ADDRESS</h4>
                                                                    <div className="deliver-detail d-flex">
                                                                        <div className="pickup">
                                                                            <form>
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label">
                                                                                        <input
                                                                                            type="radio"
                                                                                            className="form-check-input"
                                                                                            name="opt"
                                                                                            value="1"
                                                                                            checked={showNewAddress === 1}
                                                                                            onChange={handleAddressChange}
                                                                                        />
                                                                                        Ship to
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label">
                                                                                        <input
                                                                                            type="radio"
                                                                                            className="form-check-input"
                                                                                            name="opt"
                                                                                            value="2"
                                                                                            checked={showNewAddress === 2}
                                                                                            onChange={handleAddressChange}
                                                                                        />
                                                                                        New Address
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label">
                                                                                        <input
                                                                                            type="radio"
                                                                                            className="form-check-input"
                                                                                            name="opt"
                                                                                            value="3"
                                                                                            checked={showNewAddress === 3}
                                                                                            onChange={handleAddressChange}
                                                                                        />
                                                                                        Site Delivery
                                                                                    </label>
                                                                                </div>

                                                                            </form>
                                                                        </div>
                                                                        <div className="delicer-address" style={{ marginLeft: "150px" }}>
                                                                            {showNewAddress == 1 ? (
                                                                                <>
                                                                                    <p className="mb-0">
                                                                                        {defaultAddress?.address_1}<br />
                                                                                        {defaultAddress?.city}&nbsp;{defaultAddress?.state}&nbsp;{defaultAddress?.postcode}
                                                                                    </p>
                                                                                </>
                                                                            ) : showNewAddress == 2 ?
                                                                                (<>
                                                                                    {defaultAddress2?.address_1}<br />
                                                                                    {defaultAddress2?.city}&nbsp;{defaultAddress2?.state}&nbsp;{defaultAddress2?.postcode}


                                                                                </>) :
                                                                                (<>
                                                                                    {siteDeliveryAdd?.address_1}<br />
                                                                                    {siteDeliveryAdd?.city}&nbsp;{siteDeliveryAdd?.state}&nbsp;{siteDeliveryAdd?.postcode}
                                                                                    { }
                                                                                </>)

                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="row ">
                                                            <h4>ORDER SUMMARY</h4>
                                                            <ol class="" style={{ listStyle: "none" }}>
                                                                <li className='mt-2' >
                                                                    <span className="subtotal">Subtotal</span>
                                                                    <span className="subtotal-prize" style={{marginLeft:"292px"}}>${totals ? totals.subtotal : 0.00}</span>
                                                                </li>
                                                                {totals.postcodeType == 1 || totals.postcodeType == 2 ? (
                                                                    <>
                                                                        <li className='mt-2' >
                                                                            <span className="subtotal">Delivery</span>
                                                                            <span className="subtotal-prize" style={{marginLeft:"300px"}}>${totals ? totals.delivery : 0.00}</span>
                                                                        </li>
                                                                    </>
                                                                ) :
                                                                    (
                                                                        <>
                                                                            <li className='mt-2' >
                                                                                <span className="subtotal"><a href="javascript:;" onClick={() => calculateorderShippingApi(totals.cart_id)}>Click here</a> to calculate shipping.</span>

                                                                            </li>
                                                                        </>
                                                                    )
                                                                }
                                                                <li className='mt-2' >
                                                                    <span className="subtotal">GST</span>
                                                                    <span className="subtotal-prize" style={{marginLeft:"333px"}}>${totals ? totals.gstontotal : 0.00}</span>
                                                                </li>
                                                                <li className='mt-2' >
                                                                    <span className="subtotal"><b>Total</b></span>
                                                                    <span className="subtotal-prize" style={{marginLeft:"325px"}}><b>${totals ? totals.total : 0.00}</b></span>
                                                                </li>
                                                            </ol>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : ''}

                                            {orderProducts.length >0 ?
                                            <div className="col-md-12 text-end">
                                                <button type="submit" className="btn btn-primary" onClick={PlaceCODOrderApi} >Order Now</button>
                                            </div> : ""}
                                        
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default Createorder;
