import React, { useEffect, useState } from 'react'
import config from '../coreFIles/config';
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import moment from 'moment';
import Pagination from './Pagination';
import Header from '../directives/header'
import Footer from '../directives/footer'
import Sidebar from '../directives/sidebar'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Link, useParams } from 'react-router-dom';
import { getProductDetailsAction, getProductContractUsersAction, getAllActiveUsersListAction, UpdateProductAction, getProductsPriceAction, UserDeleteAction, saveproductuserAction, UsercontactPriceideleteAction } from '../Action/action';
import Cookies from "js-cookie";
const loginData = (!Cookies.get('loginSuccessfastenerwarehouseAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessfastenerwarehouseAdmin'));

const Editproduct = () => {
if (!loginData || loginData == '') {
		window.location.href = `${config.baseUrl}`;
	}
	if(loginData.role != config.role_admin){
		window.location.href = `dashboard`;	
	}
	const { id } = useParams()
	const [ProductDetails, setProductDetails] = useState([]);
	const [ContractPriceList, setContractPriceList] = useState([]);
	const [UsersList, setUsersList] = useState([]);
	const [productPrice, setProductprice] = useState([])
	const [spinloader, setspinloader] = useState(0);
	const [issubmit, setissubmit] = useState(0);
	const [validationError, setvalidationError] = useState({});
	const [form, setForm] = useState({
		user_id: '',
		price_percentage: '',
		price:0,
		product_id: id
	})

	useEffect(() => {
		setspinloader(1);
		getProductDetailsAPI();
		getProductpriceApi()
		getProductContractUsersAPI();
		getUsersListAPI();
	}, []);

	const SrNo = (index) => {
		return index + index + 1;
	};
	const getProductDetailsAPI = async () => {
		let res = await getProductDetailsAction({ product_id: id });
		if (res.success) {
			let data = res.data;
			setProductDetails(data);
			setspinloader(0);
		}else {
			setProductDetails([]);
			setspinloader(0);
		}
	}
	const handleKeyPress = (e) => {
		// Allow only numeric values (0-9) and certain control keys
		const isValidInput = /^[0-9\b]+$/.test(e.key);
		if (!isValidInput) {
		  e.preventDefault();
		}
	  }; 
	const getProductpriceApi = async () => {
		let res = await getProductsPriceAction({ product_id: id })
		if (res.success) {
			let data = res.data;
			setProductprice(data)
			setspinloader(0)
		}else {
			setProductprice([]);
			setspinloader(0);
		}
	}
	const getProductContractUsersAPI = async () => {
		let res = await getProductContractUsersAction({ product_id: id });
		if (res.success) {
			let data = res.data;
			setContractPriceList(data);
		} else {
			setContractPriceList([]);
		}
	}

	const getUsersListAPI = async () => {
		let res = await getAllActiveUsersListAction({ product_id: id });
		if (res.success) {
			let data = res.data;
			setUsersList(data);
		} else {
			setUsersList([]);
		}
	}
	const inputhandler = (e) => {
		const { name, value } = e.target
		setForm({
			...form,
			[name]: value
		})
	}

	function validate() {
		let user_idError = "";
		let priceError = "";
		if (form.user_id === '') {
			user_idError = "User is required."
		}		
		if (form.price_percentage === '') {
			priceError = "Price percentage is required."
		}

		if (user_idError || priceError) {
			setvalidationError({
				user_idError, priceError
			})
			return false
		} else {
			setvalidationError({
				user_idError, priceError
			})
			return true
		}
	}

	const handleSubmit = async (data) => {
		data.preventDefault()
		setissubmit(1);
		const isValid = validate();
		if (!isValid) {
			setissubmit(0);
		} else {
			let res = await saveproductuserAction({
				...form
			})
			if (res.success) {
				setissubmit(0);
				let data = res.data
				toast.success(res.msg)				
				getProductContractUsersAPI()
				setForm({
					user_id:'',
					price_percentage:'',
					price:0,
					product_id:id
				})
				getUsersListAPI()
			}else {
				toast.error(res.error)
				setissubmit(0);
			}
		}
	}

	const UserDelete = async (user) => {		
		Swal.fire({
			title: ' ',
			text: "Are you sure? You want to Delete this contract price!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes',
			cancelButtonText: 'No',
		}).then(async (result) => {
			if (result.isConfirmed) {
				let res = await UsercontactPriceideleteAction({ id: user.id,product_id:id });
				if (res.success) {
					setForm({
					user_id:'',
					price_percentage:'',
					price:0,
					product_id:id
				})
					toast.success(res.msg);
					getUsersListAPI()
					getProductContractUsersAPI()
				} else {
					toast.error(res.msg);
				}
			}
		});
	}

	return (

		<>
			<Toaster />
			<Sidebar />

			<div className="wrapper d-flex flex-column min-vh-100 bg-light">

				<Header />
				<div className="container-lg mb-4">
					<nav aria-label="breadcrumb">
						<ol className="breadcrumb my-0 ms-2">
							<li className="breadcrumb-item">
								<span><a className="homelink" href={`${config.baseUrl}`} >Home</a></span>
							</li>
							<li className="breadcrumb-item active"><span>Product Details</span></li>
						</ol>
					</nav>
				</div>
				<div className="body flex-grow-1 px-3">
					<div className="container-lg">
						<div className="row align-items-end">

							<div className="col-md-12">
								<div className="card mb-4">
									<div className="card-header">
										<div className="row align-items-center">
											<div className="col-md-6 text-start">
												<span>Product Details</span>
											</div>
											<div className="col-md-6 text-end">
												<a className="btn btn-primary editbtn" href={`${config.baseUrl}products`} >Back</a>
											</div>
										</div>
									</div>
									<div className="card-body inner-body">
										<Tabs>
											<TabList>
												<Tab>Product Details</Tab>
												<Tab>Product Prices</Tab>
												<Tab>Product Contract Users</Tab>
											</TabList>
											<TabPanel>
												<form className="row g-3">
													<div className="col-md-6">
														<label className="form-label" for="Part number">Part number</label>
														<input className="form-control" id="Part number" name="Part number" placeholder="Enter part number" type="text" value={ProductDetails?.part_number} disabled readOnly />
													</div>
													<div className="col-md-6">
														<label className="form-label" for="hobson part">Hobson part<span className="req-star"></span></label>
														<input className='form-control' id="hobson part" name="hobson part" placeholder="Enter hobson part" type="text" autocomplete="off" value={ProductDetails?.hobson_part} disabled readonly />

													</div>
													<div className="col-md-6">
														<label className="form-label" for="description">Description<span className="req-star"></span></label>
														<input className='form-control' id="description" name="description" placeholder="Enter description" type="text" maxlength="30" value={ProductDetails?.description} disabled readonly />

													</div>
													<div className="col-md-6">
														<label className="form-label" for="extran description">Extra description<span className="req-star"></span></label>
														<input className='form-control' id="extran description" name="extra description" placeholder="Enter extra description" type="text" value={ProductDetails?.extra_description} disabled readonly />

													</div>
													<div className="col-md-6">
														<label className="form-label" for="part prefix">Part prefix<span className="req-star"></span></label>
														<input className='form-control' id="part prefix" name="part prefix" placeholder="Enter part prefix" type="text" value={ProductDetails?.part_prefix} disabled readonly />
													</div>
													<div className="col-md-6">
														<label className="form-label" for="pack quantity">Pack quantity<span className="req-star"></span></label>
														<input className='form-control' id="pack quantity" name="pack quantity" placeholder="Enter pack quantity" type="text" value={ProductDetails?.pack_quantity} disabled readonly />

													</div>
													<div className="col-md-6">
														<label className="form-label" for="pack weight">Pack weight<span className="req-star"></span></label>
														<input className='form-control' id="pack weight" name="pack weight" placeholder="Enter pack weight" type="text" value={ProductDetails?.pack_weight} disabled readonly />

													</div>
													<div className="col-md-6">
														<label className="form-label" for="thread">Thread<span className="req-star"></span></label>
														<input className='form-control' id="thread" name="thread" placeholder="Enter thread" type="text" value={ProductDetails?.thread} disabled readonly />
													</div>
													<div className="col-md-6">
														<label className="form-label" for="Sell quantity minimum">Sell quantity minimum<span className="req-star"></span></label>
														<input className='form-control' id="Sell quantity minimum" name="Sell quantity minimum" value={ProductDetails?.sell_quantity_minimum} placeholder="Enter Sell quantity minimum" type="text" readonly disabled />

													</div>

													<div className="col-md-6">
														<label className="form-label" for="Sell quantity increment"> Sell quantity increment<span className="req-star"></span></label>
														<input className='form-control' id="Sell quantity increment" name="Sell quantity increment" placeholder="Enter Sell quantity increment" type="text" value={ProductDetails?.sell_quantity_increment} disabled readonly />

													</div>


													<div className="col-md-6">
														<label className="form-label" for="spec"> Spec<span className="req-star"></span></label>
														<input className='form-control' id="spec" name="spec" placeholder="Enter spec" type="text" value={ProductDetails?.spec} disabled readonly />

													</div>
													<div className="col-md-6">
														<label className="form-label" for="size"> Size<span className="req-star"></span></label>
														<input className='form-control' id="size" name="size" placeholder="Enter size" type="text" value={ProductDetails?.size} disabled readonly />

													</div>
													<div className="col-md-6">
														<label className="form-label" for="length">Length<span className="req-star"></span></label>
														<input className='form-control' id="length" name="length" placeholder="Enter length" type="text" value={ProductDetails?.length} disabled readonly />

													</div>
													<div className="col-md-6">
														<label className="form-label" for="finish">Finish<span className="req-star"></span></label>
														<input className='form-control' id="finish" name="finish" placeholder="Enter finish" type="text" value={ProductDetails?.fltr_finish} disabled readonly />

													</div>
													<div className="col-md-6">
														<label className="form-label" for="fltr material">Material<span className="req-star"></span></label>
														<input className='form-control' id=" material" name=" material" placeholder="Enter material" type="text" value={ProductDetails?.fltr_material} disabled readonly />

													</div>
													<div className="col-md-6">
														<label className="form-label" for="thread">Thread<span className="req-star"></span></label>
														<input className='form-control' id="thread" name="thread" placeholder="Enter thread" type="text" value={ProductDetails?.fltr_thread} disabled readonly />

													</div>
													<div className="col-md-6">
														<label className="form-label" for="paint">Paint<span className="req-star"></span></label>
														<input className='form-control' id="paint" name="paint" placeholder="Enter paint" type="text" value={ProductDetails?.fltr_paint} disabled readonly />

													</div>
													<div className="col-md-6">
														<label className="form-label" for="drive type"> Drive type<span className="req-star"></span></label>
														<input className='form-control' id="drive type" name="drive type" placeholder="Enter drive type" type="text" value={ProductDetails?.fltr_drive_type} disabled readonly />

													</div>
													<div className="col-md-6">
														<label className="form-label" for="related parts"> Related parts<span className="req-star"></span></label>
														<input className='form-control' id="related parts" name="related parts" placeholder="Enter related parts" type="text" value={ProductDetails?.related_parts} disabled readonly />

													</div>
													<div className="col-md-6">
														<label className="form-label" for="alternate parts"> Alternate parts<span className="req-star"></span></label>
														<input className='form-control' id="alternate parts" name="alternate parts" placeholder="Enter alternate parts" type="text" value={ProductDetails?.alternate_parts} disabled readonly />

													</div>
													<div className="col-md-6">
														<label className="form-label" for="packaging class"> Packaging Class<span className="req-star"></span></label>
														<input className='form-control' id="packaging class" name="packaging class" placeholder="Enter packaging class" type="text" value={ProductDetails?.packaging_class} disabled readonly />

													</div>
													<div className="col-md-6">
														<label className="form-label" for="image path"> Image path<span className="req-star"></span></label>
														<input className='form-control' id="image path" name="image path" placeholder="Enter image path" type="text" value={ProductDetails?.image_path} disabled readonly />

													</div>
													<div className="col-md-6">
														<label className="form-label" for="price per"> Price per<span className="req-star"></span></label>
														<input className='form-control' id="price per" name="price per" placeholder="Enter price per" type="text" value={ProductDetails?.price_per} disabled readonly />

													</div>
													<div className="col-md-6">
														<label className="form-label" for="poa"> Poa<span className="req-star"></span></label>
														<input className='form-control' id="poa" name="minimum_order_total" placeholder="Enter poa" type="text" value={ProductDetails?.poa} />
													</div>
													<div className="col-12 text-end d-none">
														<button className="btn btn-primary" type="submit" disabled >Update</button>
													</div>

												</form>
											</TabPanel>
											<TabPanel >
												<div className="table-responsive">
													<table className="table mb-0">
														<thead className="table-light fw-semibold">
															<tr className="align-middle table-heading">
																<th>Sr.</th>												
																<th>Price type</th>
																<th>Price</th>
																<th>Quantity break</th>
															</tr>
														</thead>

														<tbody>
															{spinloader === 0 ? (
																productPrice.length > 0 ? (
																	productPrice.map((item, index) => (
																		<tr key={index}>
																			<td colSpan="1">{index + 1}</td>
																			<td>{item.price_type}</td>
																			<td>{item.price}</td>
																			<td>{item.quantity_break}</td>
																		</tr>
																	))
																) : (
																	<tr>
																		<td colSpan="5" className="text-center">No Product Price found.</td>
																	</tr>
																)
															) : (
																<tr>
																	<td colSpan="5" className="text-center">Loading...</td>
																</tr>
															)}


														</tbody>


													</table>
												</div>
											</TabPanel>
											<TabPanel>
												<div className="table-responsive">
													<div className="card-header">	
													<div className="mb-3">
															<div className="col-md-12 text-start">
																<span>Add Users contract price</span>
															</div>
														</div>					
														<form>
														<div className="row mt-3">													
																<div className="col-md-4">						
																	<label htmlFor="filtertype" className="form-label">User*</label>
																	<div className="">
																		<select
																			className={validationError.user_idError ? 'form-control is-invalid' : 'form-control'}
																			id="user_id"
																			name="user_id"
																			value={form.user_id}
																			onChange={inputhandler}
																			required
																		>
																			  <option value="">Select user</option>
																			{UsersList.map((user,index) => (
																				<option key={index} value={user.id}>
																					{user.first_name} {user.last_name}
																				</option>
																			))}
																		</select>
																		<div className="invalid-feedback">{validationError.user_idError}</div>

																	</div>
																</div>
																<div className="col-md-2">
																	<label htmlFor="Price" className="form-label">Price percentage*</label>
																	<input
																		type="text"
																		name="price_percentage"
																		onChange={inputhandler}
																		value={form.price_percentage}
																		placeholder="price percentage..."
																		id="price_percentage"
																		data-oc-target="autocomplete-name"
																		className={validationError.priceError ? 'form-control is-invalid' : 'form-control'}
																		autoComplete="off"
																		onKeyPress={handleKeyPress}
																	/>
																	<div className="invalid-feedback">{validationError.priceError}</div>
																</div>

																<div className="col-md-2 d-none">
																	<label htmlFor="Price" className="form-label">Price</label>
																	<input
																		type="text"
																		name="price"
																		onChange={inputhandler}
																		value={form.price}
																		placeholder="price..."
																		id="price"
																		data-oc-target="autocomplete-name"
																		className='form-control'
																		autoComplete="off"
																		onKeyPress={handleKeyPress}
																	/>
																</div>
																


																<div className="col-md-4 mt-25">
																	<button className="btn-primary text-white ht-42" type="submit" onClick={handleSubmit}>{issubmit==1 ? 'Submiting...' : 'Submit'}</button>
																</div>
															</div>
														</form>
														<div className="mb-3 mt-5">
															<div className="col-md-12 text-start">
																<span>Users List</span>
															</div>

														</div>
													</div>
													
													<table className="table mb-0">
														<thead className="table-light fw-semibold">
															<tr className="align-middle table-heading">
																<th>Name</th>
																<th>Email</th>
																<th>Price percentage</th>
																<th>Action</th>

															</tr>
														</thead>

														<tbody >
															{spinloader === 0 ? (
																ContractPriceList.length > 0 ? (
																	ContractPriceList.map((user, index) => (
																		<tr key={index}>
																			<td >{user.first_name} {user.last_name}</td>
																			<td>{user.email}</td>
																			<td>{user.price_percentage}</td>
																			<td><button type="button" className="btn btn-primary deletebtn" onClick={() => UserDelete(user)} ><i className='fa fa-trash'></i> Delete</button></td>
																		</tr>
																	))
																) : (
																	<tr>
																		<td colSpan="4" className="text-center">No user found.</td>
																	</tr>
																)
															) : (
																<tr>
																	<td colSpan="6" className="text-center">Loading...</td>
																</tr>
															)}


														</tbody>


													</table>
												</div>
											</TabPanel>
										</Tabs>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</>
	)

}
export default Editproduct;
