import React, { useEffect, useState } from 'react'
import config from '../coreFIles/config';
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import moment from 'moment';
import Pagination from './Pagination';
import Header from '../directives/header';
import Footer from '../directives/footer';
import Sidebar from '../directives/sidebar';
import Cookies from "js-cookie";
import { getUserslistAction, getTotalUsersListAction, UserDeleteAction, UserBlockUnBlockAction } from '../Action/action';

const Users = () => {
	const loginData = (!Cookies.get('loginSuccessfastenerwarehouseAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessfastenerwarehouseAdmin'));
	const [UsersList, setUsersList] 				= useState([]);
	const [page, setPage] 							= useState(1); 
	const [totalPages, settotalPages] 				= useState(0);
	const [totalRecords, settotalRecords] 			= useState(0);
	const [pagingspinloader, setpagingspinloader] 	= useState(1);
	const [spinloader, setspinloader] 				= useState(0);
	const [RolesList, setRolesList] 				= useState([]);
	const [emailFilter, SetemailFilter] 			= useState('');	
	const [statusFilter, SetstatusFilter] 			= useState('');	
    useEffect(()=>{
	   setspinloader(1);
	    getTotalUsersListAPI();
        getUserslist();       
    },[]);
    
     
  const getTotalUsersListAPI = async () => {
        let res = await getTotalUsersListAction({email:emailFilter,status:statusFilter});
        if (res.success) {
            settotalPages(res.data);
            settotalRecords(res.totalRecords);
            setpagingspinloader(0);            
		}
        else
        {
			settotalPages(0);
			settotalRecords(0);		
			setpagingspinloader(0);	
		}
		
		
    }
  
  const getUserslist = async () => {
		let res = await getUserslistAction({email:emailFilter,status:statusFilter,page:page});
		if (res.success) {
			let data = res.data;			
			setUsersList(data);	
			setspinloader(0);
		}
		else
		{
			setUsersList([]);	
			setspinloader(0);
		}
	}
      
  const UserDelete = async (user_id) => {
		Swal.fire({
			title: 'Are you sure? ',
			text: "You want to Delete this User!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
		}).then(async (result) => {
			if (result.isConfirmed) {
				let res = await UserDeleteAction({'user_id':user_id});
				if (res.success) {
					toast.success(res.msg);
					getUserslist();
				} else {
					toast.error(res.msg);
				}
			}
		});
	}
   
  const handleStatus = async (id, is_active) => {
        Swal.fire({
			title: 'Are you sure?',
            text: is_active === 0 ? "Are you sure you want to disable this User?" : "Are you sure you want to enable this User?",
            icon: 'warning',        
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: is_active == 0 ? 'Yes, Disable it!' : 'Yes, Enable it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await await UserBlockUnBlockAction({ 'id': id, 'is_active': is_active });
                if (res.success) {
                    getUserslist();
                    if(is_active==0)
                    toast.success("User disabled successfully!");
                    else
                     toast.success("User enabled successfully!");                    
                } else {
					 toast.success(res.msg);                   
                }
            }
        })
    }
 
  const onPageChanged = async (data)  => {
		const { currentPage, totalPages, pageLimit } = data;
		const offset = (currentPage - 1) * pageLimit;
		let res = await getUserslistAction({email:emailFilter,status:statusFilter,page:offset});
        if (res.success) {
            setUsersList(res.data);
        }
        else
        {
			setUsersList([]);	
		}

  }	
  const SetemlFilter = async (e) => {
        SetemailFilter(e.target.value);        
    }  
  const SetStFilter = async (e) => {
        SetstatusFilter(e.target.value);        
    }    
  const FilterUsers = async (e) => {
		setPage(1);	
        getTotalUsersListAPI();
        getUserslist();       
    }
  const ClearFilter = async (e) => {
	  SetemailFilter('');  
	  SetstatusFilter(''); 	       
	  setPage(1);	
      let res = await getTotalUsersListAction({email:'',status:'',role:''});
        if (res.success) {
            settotalPages(res.data);
            settotalRecords(res.totalRecords);
            setpagingspinloader(0);            
		}
        else
        {
			settotalPages(0);
			settotalRecords(0);		
			setpagingspinloader(0);	
		}
      let list = await getUserslistAction({email:'',status:'',page:1});
		if (list.success) {
			setUsersList(list.data);	
		}
		else
		{
			setUsersList([]);	
		}      
    }
    

  return (

    <>
      <Sidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        
        <Header />       
        <div className="container-lg mb-4">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb my-0 ms-2">
              <li className="breadcrumb-item">
               <span><a className="homelink" href={`${config.baseUrl}users`} >Home</a></span>
              </li>
              <li className="breadcrumb-item active"><span>Users</span></li>
            </ol>
          </nav>
        </div>
        <div className="body flex-grow-1 px-3">
        <div className="container-lg">
          <div className="row align-items-end">
            
          <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header">
					<div className="row align-items-center">				
						<div className="col-md-6 text-start">
						 <span>Users</span>
						</div>
						<div className="col-md-6 text-end">
					 <a className="btn btn-primary createbtn" href={`${config.baseUrl}ad-user`} ><i className="fa fa-plus"></i> Create User</a>
						</div>
					</div>
					<div className="row align-items-end">
						<div className="col-lg-4 mt-3 searching">	
						<label className="form-label">User Email</label>	
						<div className="display-flex">									 
						  <input type="text" name="email" placeholder="Search..." id="input-name" data-oc-target="autocomplete-name" className="form-control" autocomplete="off" onChange={SetemlFilter} value={emailFilter}/>
						  
							</div>
						</div>
						<div className="col-lg-2 col-md-6 mt-3">	
						<label className="form-label">Status</label>						 
							<select className="form-control" id="is_active" name="is_active" onChange={SetStFilter} value={statusFilter}>
									  <option value="">Select Status</option>								
									  <option value="1">Active</option>								
									  <option value="0">In-Active</option>								
									</select>
						</div>						
						<div className="col-lg-4 col-md-12 mt-3">
						  <button type="button" id="button-filter" className="btn btn-info" onClick={() => FilterUsers()}><i className="fa fa-search"></i> Search</button>
						  &nbsp;
						  <button type="button" id="button-filter" className="btn btn-primary" onClick={() => ClearFilter()}>Clear</button>
						</div>
					</div>	
                </div>
                
                <div className="card-body">
                
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead className="table-light fw-semibold">
                        <tr className="align-middle table-heading">
                          <th>Sr.</th>
                          <th>User Name</th>
                          <th>Email</th>                          
                          <th>Phone</th>                          
                          <th>address</th>                          
                          <th>Status</th>   
                          { loginData.role == config.role_admin ? (<><th>Action</th></>):null  }
						</tr>
                      </thead>
                      <tbody>
                        { spinloader==0 ? (
						UsersList.length > 0  ?
							UsersList.map((xuser,index)=>{
								return(
									<tr key={index}>
										<td>{index+1}</td>
										<td>{xuser.first_name+' '+xuser.last_name}</td>
										<td><span className="user-email"><Link to={`${config.baseUrl}edituser/` + xuser.id}>{xuser.email}</Link></span></td>										
										<td>{xuser.phone}</td>										
										<td>{xuser.address_1}</td>					
										<td>
										{ loginData.role == config.role_admin ? (<>
												<div className="form-check form-switch">
												  <input
													className="form-check-input"
													id={`userstatus_${index}`}
													type="checkbox"
													checked={xuser.is_active === 1}
													onChange={() => handleStatus(xuser.id, xuser.is_active === 1 ? 0 : 1)}
												  />
											</div>
											</>):(<>
											<div className="form-check form-switch" title={xuser.is_active === 1 ? 'Active' : 'In-active'}>
											  <input
												className="form-check-input"
												id={`userstatus_${index}`}
												type="checkbox"
												checked={xuser.is_active === 1}
												/>
											</div>
											
											</>) }
										
										
										
										</td>	
											{ loginData.role == config.role_admin ? (<>
												<td>
													<Link to={`${config.baseUrl}edit-user/` + xuser.id}>
															<button className='btn btn-primary editbtn'><i className="far fa-edit"></i> Edit</button>
														</Link>
														&nbsp;&nbsp; 
													<button type="button" className="btn btn-primary deletebtn" onClick={() => UserDelete(xuser.id)}><i className='fa fa-trash'></i> Delete</button>
												</td>
											</>):null }
										
										
									</tr>
								)
							})
							:
							(
								<tr><td colspan="7" className="text-center">No user found.</td></tr>
							)
						
						):
							(
								<tr><td colspan="7" className="text-center"><img className="loaderImg search-loader" src={config.baseUrl + "images/loader.gif"} height={20} width={20} /></td></tr>
							) }
                      
                      </tbody>
                    </table>
                  </div>
                   {totalPages > 0 ?										  
						 <Pagination totalRecords={totalRecords} totalPages={totalPages} pageNeighbours={page} onPageChanged={onPageChanged}   />
						 : ''
					 }	
                </div>
              </div>
            </div>
          </div>          
        </div>
      </div>    
      <Footer />
      </div>
    </>
  )

}
export default Users;
