import React, { useEffect, useState } from 'react'
import config from '../coreFIles/config';
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import Pagination from './Pagination';
import Header from '../directives/header'
import Footer from '../directives/footer'
import Sidebar from '../directives/sidebar'
import {getFilterTypesListAction,getFilterListAction, getTotalFiltersListAction, FilterBlockUnBlockAction } from '../Action/action';
import Cookies from "js-cookie";
const loginData = (!Cookies.get('loginSuccessfastenerwarehouseAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessfastenerwarehouseAdmin'));
const Filters = () => {
	if (!loginData || loginData == '') {
		window.location.href = `${config.baseUrl}`;
	}
	if(loginData.role != config.role_admin){
		window.location.href = `dashboard`;	
	}
    const [FiltersList, setFiltersList] = useState([]);	  
    const [FiltersTypeList, setFiltersTypeList] = useState([]);	  
    const [page, setPage] = useState(1); 
    const [totalPages, settotalPages] = useState(0);
    const [totalRecords, settotalRecords] = useState(0);
    const [pagingspinloader, setpagingspinloader] = useState(0); 
    const [filterType, setfilterType] = useState(1); 
    const [spinloader, setspinloader] 				= useState(0);

    useEffect(()=>{
        setpagingspinloader(1);	   
        setspinloader(1);
        getFiltersTypeAPI();
        getFiltersListAPI();
        getTotalFiltersListAPI();
    }, []);
    
    const getFiltersTypeAPI = async () => {
        let res = await getFilterTypesListAction({type: filterType});
        if (res.success) {      
            setFiltersTypeList(res.data);
            console.log(res.data)
        } else {
            setFiltersTypeList([]);	
        }   
    };
    
    const getTotalFiltersListAPI = async () => {
        let res = await getTotalFiltersListAction({type: filterType});
        if (res.success) {
            settotalPages(res.data);
            settotalRecords(res.totalRecords);
            setpagingspinloader(0);
        } else {
            settotalPages(0);
            settotalRecords(0);		
            setpagingspinloader(0);	
        }
    };
   
    const getFiltersListAPI = async () => {
        let res = await getFilterListAction({type: filterType, page: page});
        if (res.success) {      
            setFiltersList(res.data);
             setspinloader(0);
        } else {
            setFiltersList([]);	
             setspinloader(0);
        }   
    };
      
    const onPageChanged = async (data)  => {
        const { currentPage, pageLimit } = data;
        setPage(currentPage); // Update the current page in the state
        const offset = (currentPage - 1) * pageLimit;
        let res = await getFilterListAction({type: filterType, page: currentPage});
        if (res.success) {
            setFiltersList(res.data);
        } else {
            setFiltersList([]);
        }
    };
  
    const SetFiltertype = async (e) => {
        setfilterType(e.target.value); 
    }; 
    
    const SearchFilter = async (e) => {
		 setspinloader(1);
        setPage(1); 
        await getTotalFiltersListAPI(); 
        await getFiltersListAPI();
    };
    
    const ClearFilter = async (e) => {
        window.location.href = `${config.baseUrl}filters`;
    };
 
    const handleStatus = async (id, is_active) => {
        Swal.fire({			
            title: 'Are you sure?',
            text: is_active === 0 ? "Are you sure you want to disable this Filter?" : "Are you sure you want to enable this Filter?",
            icon: 'warning',         
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: is_active === 0 ? 'Yes, Disable it!' : 'Yes, Enable it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await FilterBlockUnBlockAction({ id, is_active });
                if (res.success) {
                    getFiltersListAPI();
                    if (is_active === 0)
                        toast.success("Filter disabled successfully!");
                    else
                        toast.success("Filter enabled successfully!"); 
                } else {
                    toast.error(res.msg);       
                }
            }
        });
    };

    return (
        <>
            <Sidebar />
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                <Toaster />
                <Header />
                <div className="container-lg mb-4">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb my-0 ms-2">
                            <li className="breadcrumb-item">
                                <span><a className="homelink" href={`${config.baseUrl}dashboard`} >Home</a></span>
                            </li>
                            <li className="breadcrumb-item active"><span>Filters</span></li>
                        </ol>
                    </nav>
                </div>
                <div className="body flex-grow-1 px-3">
                    <div className="container-lg">
                        <div className="row align-items-end">
                            <div className="col-md-12">
                                <div className="card mb-4">
                                    <div className="card-header">
                                        <div className="row align-items-center">				
                                            <div className="col-md-6 text-start">
                                                <span>Search Filter</span>
                                            </div>						
                                        </div>					
                                        <div className="row mb-4 mt-4 align-items-end">
                                            <div className="col-lg-4 col-xl-2 col-md-6 mt-3">
                                                <label className="form-label">Filter</label>								 
                                                <select className="form-control" id="filtertype" name="filtertype" onChange={SetFiltertype} value={filterType}>
                                                    <option value="0">Select Filter</option>								
                                                    {
                                                        FiltersTypeList.map((item, index) => (
                                                            <option value={item.id} key={index}>{item.description}</option>
                                                        ))
                                                    }							
                                                </select>
                                            </div>
                                            <div className="col-lg-4 col-xl-3 col-md-12 mt-4">
                                                <button type="button" id="button-filter" className="btn btn-info" onClick={() => SearchFilter()}><i className="fa fa-search"></i> Search</button>
                                                &nbsp;
                                                <button type="button" id="button-filter" className="btn btn-primary" onClick={() => ClearFilter()}>Clear</button>
                                            </div>
                                        </div>	
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table mb-0">
                                                <thead className="table-light fw-semibold">
                                                    <tr className="align-middle table-heading">
                                                        <th>Sr.</th>
                                                        <th>Title</th>
                                                        <th>Description</th>
                                                        <th>Type</th>                          
                                                        <th>Status</th>                         
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                  {  spinloader==0 ? (
													   FiltersList.length > 0 ?
                                                        FiltersList.map((item, index) => (
                                                            <tr key={index}>
                                                                <td>{index+1}</td>
                                                                <td>{item.title}</td>
                                                                <td>{item.description}</td>
                                                                <td>{item.filter_type_description}</td>										
                                                                <td>
                                                                    <div className="form-check form-switch">
                                                                        <input
                                                                            className="form-check-input"
                                                                            id={`flexSwitchCheckChecked_${index}`}
                                                                            type="checkbox"
                                                                            checked={item.is_active === 1}
                                                                            onChange={() => handleStatus(item.id, item.is_active === 1 ? 0 : 1)}
                                                                        />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    :
                                                       ( <tr><td colSpan="8" className="text-center">No filter found.</td></tr>)
                                                    ):
                                                       ( <tr><td colSpan="8" className="text-center"><img className="loaderImg search-loader" src={config.baseUrl + "images/loader.gif"} height={20} width={20} /></td></tr>)
                                                       }			 
                                                </tbody>
                                            </table>
                                          
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>          
                    </div>
                </div>       		     
                <Footer />
            </div>
        </>
    );

};

export default Filters;
