import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import Header from '../directives/header'
import Footer from '../directives/footer'
import Sidebar from '../directives/sidebar'
import { getHobsonCategorylistAction,getHobsonProductslistAction,getHobsonProductsPriceAction, UpdateProductslistthreadAction } from '../Action/action';
const Dashboard = () => {
	const [page, setPage] 							= useState(1); 
	useEffect(()=>{
	    
    },[]);
    
   const getHobsonCategorylist = async () => {
		let res = await getHobsonCategorylistAction();
		if (res.success) {
			toast.success(res.msg);
		}
	}  
	
   const getHobsonProducts = async () => {
		let res = await getHobsonProductslistAction({page:page});
		if (res.success) {
			toast.success(res.msg);
		}
	} 
	
   const getHobsonProductsPrice = async () => {
		let res = await getHobsonProductsPriceAction({page:page});
		if (res.success) {
			toast.success(res.msg);
		}
	} 
   const UpdateProductslistthread = async () => {
		let res = await UpdateProductslistthreadAction({page:page});
		if (res.success) {
			toast.success(res.msg);
		}
	} 
	
	const handleInputChange = (e) => {
		const { name, value } = e.target;	
		if (/^\d*$/.test(value)) {		 
		 setPage(value);
		}
	  }; 

  return (

    <>
      <Sidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
         <Toaster />
        <Header />
        
        <div className="body flex-grow-1 px-3">
        <div className="container-lg">
          <div className="row">
           <p>Welcome to Fastner Warehouse Admin Section</p>  
           <div className="col-md-2 d-none">         
            <button type="button" id="button-filter" className="btn btn-primary" onClick={() => getHobsonCategorylist()}>Get Hobson Categories</button>
            </div>
           <div className="col-md-2 d-none">  
           <input type="text" name="page" id="page" className="form-control" value={page} onChange={handleInputChange}	/>      
            <button type="button" id="button-filter" className="btn btn-primary" onClick={() => getHobsonProductsPrice()}>Get Hobson Products Price</button>
            </div>
           <div className="col-md-4 d-none">  
           <input type="text" name="page" id="page" className="form-control" value={page} onChange={handleInputChange}	/>      
            <button type="button" id="button-filter" className="btn btn-primary" onClick={() => UpdateProductslistthread()}>Get Hobson Products Stock</button>
            </div>           
          
           
        </div>
        </div>
      </div>    
      <Footer />
      </div>
    </>
  )

}
export default Dashboard;
